import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PageTitle from 'src/components/PageTitle/PageTitle';
import TechniciansContributionGraph from './TechniciansContributionGraph';
import OrdersStatsProgress from './OrdersStatsProgress/OrdersStatsProgress';
import OrdersCardList from './OrdersCardList/OrdersCardList';
import { startOfWeek } from 'date-fns';
import WeekSelect from './WeekSelect';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import { UserResponse } from 'src/api';

const ROLES_WITH_CONTRIBUTION_GRAPH = [
  UserResponse.role.SYSTEM_ADMINISTRATOR,
  UserResponse.role.ADMINISTRATOR_TECHNICIAN,
  UserResponse.role.ADMINISTRATOR,
  UserResponse.role.DISPATCHER,
  UserResponse.role.DISPATCHER_TECHNICIAN,
];

const HomePage: FC = () => {
  const { data: currentUser } = useCurrentUser();
  const [startOfTheWeek, setStartOfTheWeek] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));

  return (
    <div className='flex flex-col w-full'>
      <PageTitle>
        <FormattedMessage id='app.dashboard.page_title' />
      </PageTitle>

      <WeekSelect className='self-end mb-16' weekStart={startOfTheWeek} onWeekStartChange={setStartOfTheWeek} />

      <div className='grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3'>
        <OrdersStatsProgress dayOfTheWeek={startOfTheWeek} />
        {currentUser && ROLES_WITH_CONTRIBUTION_GRAPH.includes(currentUser?.role) && (
          <TechniciansContributionGraph dayOfTheWeek={startOfTheWeek} />
        )}
      </div>
      <OrdersCardList dayOfTheWeek={startOfTheWeek} />
    </div>
  );
};

export default HomePage;
