import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { OrderExcelRow } from './useOrderToExcelRow';

const useOrderExcelRowHeader = (): Record<keyof OrderExcelRow, string> => {
  const intl = useIntl();

  return useMemo(
    () => ({
      datetime: intl.formatMessage({ id: 'app.order.datetime' }),
      endDatetime: intl.formatMessage({ id: 'app.order.end_time' }),
      realStart: intl.formatMessage({ id: 'app.order.real_start_time' }),
      realEnd: intl.formatMessage({ id: 'app.order.real_end_time' }),
      status: intl.formatMessage({ id: 'app.order.status' }),
      invoiceNumber: intl.formatMessage({ id: 'app.order.invoice_number' }),
      deliveryType: intl.formatMessage({ id: 'app.finish_order.delivery_type' }),
      deliveryTariffName: intl.formatMessage({ id: 'app.delivery_tariff.full_name' }),
      orderNumber: intl.formatMessage({ id: 'app.order.number' }),
      serviceDuration: intl.formatMessage({ id: 'app.finish_order.work_duration' }),
      serviceMileage: intl.formatMessage({ id: 'app.finish_order.service_mileage' }),
      serviceTimeOnRoad: intl.formatMessage({ id: 'app.finish_order.time_on_road' }),
      serviceTotalPrice: intl.formatMessage({ id: 'app.finish_order.service_total_price_without_vat' }),
      serviceTotalPriceWithVat: intl.formatMessage({ id: 'app.finish_order.service_total_price_without_vat' }),
      technicianNames: intl.formatMessage({ id: 'app.order.technician_names' }),
      companyName: intl.formatMessage({ id: 'app.contact.company_name' }),
      companyAddress: intl.formatMessage({ id: 'app.common.address' }),
      branchName: intl.formatMessage({ id: 'app.branch.name' }),
      branchAddress: intl.formatMessage({ id: 'app.branch.address' }),
      branchContactPerson: intl.formatMessage({ id: 'app.branch.contact_person' }),
      employeehName: intl.formatMessage({ id: 'app.employee.name' }),
      productNames: intl.formatMessage({ id: 'app.order.product_names' }),
      paymentMethodName: intl.formatMessage({ id: 'app.payment_method.name' }),
    }),
    [intl],
  );
};

export default useOrderExcelRowHeader;
