import { FormattedMessage } from 'react-intl';
import { CheckboxGroupOption } from 'src/components/fields/CheckboxGroup';

export const GENERAL_INFO_FIELDS: CheckboxGroupOption<string>[] = [
  {
    name: 'deliveryType',
    value: 'deliveryType',
    label: () => <FormattedMessage id='app.finish_order.delivery_type' />,
  },
  {
    name: 'deliveryTariffId',
    value: 'deliveryTariffId',
    label: () => <FormattedMessage id='app.finish_order.delivery_tariff' />,
  },
  {
    name: 'deliveryPrice',
    value: 'deliveryPrice',
    label: () => <FormattedMessage id='app.finish_order.delivery_price' />,
  },
  {
    name: 'deliveryRateId',
    value: 'deliveryRateId',
    label: () => <FormattedMessage id='app.finish_order.delivery_rate' />,
  },
  {
    name: 'serviceMileage',
    value: 'serviceMileage',
    label: () => <FormattedMessage id='app.finish_order.service_mileage' />,
  },
  {
    name: 'serviceTimeOnRoad',
    value: 'serviceTimeOnRoad',
    label: () => <FormattedMessage id='app.finish_order.time_on_road' />,
  },
  {
    name: 'serviceDuration',
    value: 'serviceDuration',
    label: () => <FormattedMessage id='app.finish_order.work_duration' />,
  },
];

export const PRODUCT_HIDDEN_FIELDS: CheckboxGroupOption<string>[] = [
  {
    name: 'productDescription',
    value: 'productDescription',
    label: () => <FormattedMessage id='app.order_type.product_description' />,
  },
  {
    name: 'problemDescription',
    value: 'problemDescription',
    label: () => <FormattedMessage id='app.finish_order.problem_description' />,
  },
  {
    name: 'repairDescription',
    value: 'repairDescription',
    label: () => <FormattedMessage id='app.finish_order.repair_description' />,
  },
  {
    name: 'photo',
    value: 'photo',
    label: () => <FormattedMessage id='app.finish_order.photo' />,
  },
  {
    name: 'quarante',
    value: 'quarante',
    label: () => <FormattedMessage id='app.order.quarantee' />,
  },
];
