import { FC } from 'react';
import { OrderListItemResponse, OrderStatusResponse } from 'src/api';
import {
  getEventBackgroundClassNames,
  getEventBorderClassNames,
} from 'src/components/EventsGrid/components/Event/helpers';
import { twMerge } from 'tailwind-merge';
import { format, parseISO } from 'date-fns';

export type MonthTabSmallEventProps = {
  className?: string;
  data: OrderListItemResponse;
  onOrderClick?: (orderId: number) => void;
};

const MonthTabSmallEvent: FC<MonthTabSmallEventProps> = ({ className, data, onOrderClick }) => {
  const { status } = data;
  const start = data.datetime ? parseISO(data.datetime) : null;

  return (
    <span
      onClick={() => onOrderClick?.(data.id)}
      className={twMerge(
        'cursor-pointer flex flex-col rounded border-l-[6px] p-1 text-xs overflow-hidden leading-5',
        status?.name && getEventBackgroundClassNames(status.name as OrderStatusResponse.name),
        status?.name && getEventBorderClassNames(status.name as OrderStatusResponse.name),
        className,
      )}
    >
      <div className='flex justify-between'>
        <span className='overflow-hidden text-ellipsis whitespace-nowrap'>{data.orderNumber}</span>
        <span>{start && format(start, 'hh:mm')}</span>
      </div>
    </span>
  );
};

export default MonthTabSmallEvent;
