import { DeepPartial } from 'react-hook-form';
import { OrderResponse } from 'src/api';
import { OrderFinishSchema } from 'src/pages/TechnicianOrderFinishPage/components/FinishOrderForm/schema';

const mergeSavedFormWithOrder = (order: OrderResponse, savedForm: DeepPartial<OrderFinishSchema>): OrderResponse => {
  const base = { ...order, ...savedForm };

  for (let i = 0; i < (savedForm.products?.length ?? 0); i++) {
    base.orderProducts[i] = {
      ...order.orderProducts[i],
      ...savedForm.products?.[i],
    } as unknown as (typeof order.orderProducts)[number];
  }

  return base as OrderResponse;
};

export default mergeSavedFormWithOrder;
