import clsx from 'clsx';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

export type NoSidebarLayoutProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const NoSidebarLayout: FC<PropsWithChildren<NoSidebarLayoutProps>> = ({ className, children }) => {
  return (
    <div className={clsx(className, 'flex flex-col items-center lg:px-24 lg:py-12 md:px-12 md:py-8 px-4 py-4')}>
      <div className='flex flex-col w-full max-w-6xl'>{children}</div>
    </div>
  );
};

export default NoSidebarLayout;
