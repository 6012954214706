/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';

export type UseUnsetFormSet<TFieldValues extends FieldValues> = <
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  name: TFieldName,
) => void;

export function useUnsetFormField<TFieldValues extends FieldValues>(): <
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  name: TFieldName,
  unsetValue?: any,
) => void {
  const form = useFormContext<TFieldValues>();
  const { setValue } = form;

  const unsetFormField = useCallback(
    <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(name: TFieldName, unsetValue?: any) => {
      setValue(name, (unsetValue as any) ?? undefined);
    },
    [setValue],
  );

  return unsetFormField;
}
