import clsx from 'clsx';
import { addMinutes, format, formatISO, setMinutes } from 'date-fns';
import { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import useAllTechnicians from 'src/api/hooks/queries/useAllTechnicians';
import useAllTechniciansDayOrders from 'src/api/hooks/queries/useAllTechniciansDayOrders';
import EventsGrid from 'src/components/EventsGrid';
import DraggableEvent from 'src/components/EventsGrid/components/DraggableEvent';
import HorizontalLinesMTD from 'src/components/EventsGrid/components/HorizontalLinesMTD';
import HoursBarMTD from 'src/components/EventsGrid/components/HoursBarMTD';
import PotentialEvent from 'src/components/EventsGrid/components/PotentialEvent/PotentialEvent';
import TechniciansBar from 'src/components/EventsGrid/components/TechniciansBar';
import VerticalLinesMTD from 'src/components/EventsGrid/components/VerticalLinesMTD';
import {
  COLS_PER_HOUR_MTD,
  GRID_CELL_HEIGHT_MTD,
  GRID_CELL_WIDTH_MTD,
  MINUTES_PER_COL_MTD,
} from 'src/components/EventsGrid/constants';
import {
  eventToGridMTDFactory,
  getGridCellDimensionsMTD,
  gridToTimeSpanMTDFactory,
} from 'src/components/EventsGrid/helpers/gridMtd';
import useHandleDropMTDFactory from 'src/components/EventsGrid/hooks/useHandleDropMTDFactory';
import { GridEvent } from 'src/components/EventsGrid/types';
import Loader from 'src/components/utils/Loader';
import { snapToHalfHour } from 'src/helpers/datetime';
import orderListItemToGridEvent from 'src/helpers/orderListItemToGridEvents';
import AddServicePopup from 'src/pages/OrdersPage/AddServicePopup';
import { OrderSchema } from 'src/pages/OrdersPage/ServiceForm/schema';
import useCalendarTechnicianIdsParam from '../../hooks/useCalendarTechnicianIdsParam';

export type AllTechniciansCalendarProps = {
  className?: string;
  disableGridClick?: boolean;
  disableEventPreviewOnClick?: boolean;
  disableDnd?: boolean;
  disableTechnicianPreviewOnClick?: boolean;
  selectedDate: Date;
  potentialEvents?: GridEvent[];
};

const START_HOUR = 0;
const END_HOUR = 24;

const AllTechniciansCalendar: FC<AllTechniciansCalendarProps> = ({
  className,
  disableGridClick,
  disableEventPreviewOnClick,
  disableDnd,
  disableTechnicianPreviewOnClick,
  potentialEvents,
  selectedDate,
}) => {
  const { data: technicians, isLoading } = useAllTechnicians();
  const [technicianIds] = useCalendarTechnicianIdsParam();
  const { data: orders } = useAllTechniciansDayOrders(selectedDate);

  const events = useMemo(
    () => [...(orders?.map(orderListItemToGridEvent).flat() ?? []), ...(potentialEvents ?? [])],
    [orders, potentialEvents],
  );

  const visibleEvents = useMemo(() => {
    if (!technicianIds.length || technicianIds.length === technicians?.length) return events;
    return events.filter((i) => i.technicianId === null || technicianIds.includes(i.technicianId));
  }, [technicianIds, events, technicians]);
  console.log(visibleEvents);

  const visibleTechnicians = useMemo(() => {
    if (!technicianIds.length || technicianIds.length === technicians?.length) return technicians ?? [];
    return technicians?.filter((i) => technicianIds.includes(i.id)) ?? [];
  }, [technicians, technicianIds]);

  const eventToGrid = eventToGridMTDFactory(visibleTechnicians ?? []);

  const handleDropFactory = useHandleDropMTDFactory(visibleEvents, visibleTechnicians ?? []);

  const [isAddServicePopupOpen, setIsAddServicePopupOpen] = useState(false);
  const [addServicePopupDefaultValues, setAddServicePopupDefaultValues] = useState<Partial<OrderSchema>>({});

  const handleGridClickFactory = (date: Date) => (col: number, row: number) => {
    const technician = visibleTechnicians?.[row - 1];
    const minutesFromDayStart = snapToHalfHour(START_HOUR * 60 + MINUTES_PER_COL_MTD * col);

    if (!technician) return;

    const start = setMinutes(date, minutesFromDayStart);
    const end = addMinutes(start, 60);

    setAddServicePopupDefaultValues({
      technicianIds: [{ technicianId: technician.id }],
      _date: start,
      _start: format(start, 'HH:mm'),
      _end: format(end, 'HH:mm'),
      datetime: formatISO(start),
      endDatetime: formatISO(end),
    });
    setIsAddServicePopupOpen(true);
  };

  // fix scroll bar breaking aligment on windows
  const [scrollBarHeight, setScrollBarHeight] = useState(0);
  const daysContainerRef = useRef<HTMLDivElement>(null);
  const firstDayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!daysContainerRef.current || !firstDayRef.current) return;

    const containerHeight = daysContainerRef.current.getBoundingClientRect().height;
    const firstDayHeight = firstDayRef.current.getBoundingClientRect().height;

    const scrollBarHeight = containerHeight - firstDayHeight;

    setScrollBarHeight(scrollBarHeight);
  }, [daysContainerRef.current, firstDayRef.current]);

  const focusedDayRef = useRef<HTMLDivElement>(null);

  // auto scroll to today
  useEffect(() => {
    if (!focusedDayRef.current) return;

    focusedDayRef.current?.scrollIntoView({ inline: 'start' });
  }, [focusedDayRef.current, selectedDate]);

  if (isLoading) return <Loader />;
  if (!technicians) return <Navigate to='/500' />;

  return (
    <>
      <div className={clsx(className, 'flex')}>
        <div
          className='flex flex-col justify-between'
          style={{
            paddingBottom: scrollBarHeight,
          }}
        >
          <TechniciansBar
            technicians={visibleTechnicians ?? []}
            disableTechnicianPreviewOnClick={disableTechnicianPreviewOnClick}
            className='self-end'
          />
        </div>
        <div
          className={clsx(
            'flex gap-x-8 overflow-x-auto rounded-r border-r border-b border-neutral-300 overflow-hidden',
          )}
          ref={daysContainerRef}
        >
          <EventsGrid
            disableEventPreviewOnClick={disableEventPreviewOnClick}
            className='flex-col flex-auto'
            events={visibleEvents}
            cols={COLS_PER_HOUR_MTD * (END_HOUR - START_HOUR)}
            rows={visibleTechnicians.length + 1}
            startHour={START_HOUR}
            endHour={END_HOUR}
            cellWidth={GRID_CELL_WIDTH_MTD}
            cellHeight={GRID_CELL_HEIGHT_MTD}
            scrollContainerRef={{ current: null } as RefObject<HTMLElement>}
            eventToGrid={eventToGrid}
            gridToTimeSpan={gridToTimeSpanMTDFactory(selectedDate)}
            getGridCellDimensions={getGridCellDimensionsMTD}
            onDrop={handleDropFactory(selectedDate)}
            onGridClick={!disableGridClick ? handleGridClickFactory(selectedDate) : undefined}
            renderEvent={(event, props) =>
              event.isPotential ? (
                <PotentialEvent {...props} {...event} />
              ) : (
                <DraggableEvent key={event.id} {...event} {...props} disableDrag={disableDnd} resizable />
              )
            }
            renderHoursBar={() => <HoursBarMTD />}
            renderHorizontalLines={() => <HorizontalLinesMTD />}
            renderVerticalLines={() => <VerticalLinesMTD />}
          />
        </div>
      </div>
      <AddServicePopup
        defaultValues={addServicePopupDefaultValues}
        open={isAddServicePopupOpen}
        onClose={() => setIsAddServicePopupOpen(false)}
      />
    </>
  );
};

AllTechniciansCalendar.displayName = 'AllTechniciansCalendar';

export default AllTechniciansCalendar;
