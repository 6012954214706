import { format } from 'date-fns';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { OrderResponse, OrderStatusResponse } from 'src/api';
import { MessageId } from 'src/types/commonTypes';

export type OrderExcelRow = Pick<
  OrderResponse,
  | 'orderNumber'
  | 'datetime'
  | 'endDatetime'
  | 'realStart'
  | 'realEnd'
  | 'status'
  | 'invoiceNumber'
  | 'serviceDuration'
  | 'serviceMileage'
  | 'serviceTimeOnRoad'
  | 'serviceTotalPrice'
> & {
  deliveryType: string;
  technicianNames: string;
  companyName: string | null;
  companyAddress: string;
  branchName: string;
  employeehName: string;
  productNames: string;
  deliveryTariffName: string | null;
  serviceTotalPriceWithVat: number | null;
  branchAddress: string;
  branchContactPerson: string;
  paymentMethodName: string | null;
};

const useOrderToExcelRow = (): ((order: OrderResponse) => OrderExcelRow) => {
  const intl = useIntl();

  return useCallback(
    (order: OrderResponse): OrderExcelRow => ({
      datetime: order.datetime && format(new Date(order.datetime), 'dd.MM.yyyy HH:mm'),
      endDatetime: order.endDatetime && format(new Date(order.endDatetime), 'dd.MM.yyyy HH:mm'),
      realStart: order.realStart && format(new Date(order.realStart), 'dd.MM.yyyy HH:mm'),
      realEnd: order.realEnd && format(new Date(order.realEnd), 'dd.MM.yyyy HH:mm'),
      status: order.status
        ? intl.formatMessage({
            id: `app.order_status.${order.status.toLowerCase() as Lowercase<OrderStatusResponse.name>}`,
          })
        : '',
      invoiceNumber: order.invoiceNumber,
      deliveryType: order.deliveryType
        ? intl.formatMessage({ id: `app.finish_order.delivery_type.${order.deliveryType.toLowerCase()}` as MessageId })
        : '',
      orderNumber: order.orderNumber,
      serviceDuration: order.serviceDuration,
      serviceMileage: order.serviceMileage,
      serviceTimeOnRoad: order.serviceTimeOnRoad,
      serviceTotalPrice: order.serviceTotalPrice,
      serviceTotalPriceWithVat:
        order.serviceTotalPrice && order.vatTariff
          ? (order.serviceTotalPrice * (100 + order.vatTariff.tariffValue)) / 100
          : null,
      technicianNames: order.technicians
        .map((technician) => technician?.profile?.name)
        .filter(Boolean)
        .join(', '),
      companyName: order.company?.companyName ?? null,
      companyAddress: order.company ? `${order.company.street}, ${order.company.zip} ${order.company.city}` : '',
      branchName: order.branch?.name ?? '',
      branchAddress: order.branch ? `${order.branch.street}, ${order.branch.zip} ${order.branch.city}` : '',
      branchContactPerson: order.branch?.contactPerson ?? '',
      employeehName: order.employee?.name ?? '',
      productNames: order.orderProducts.map((op) => op.product?.productName ?? '').join(', '),
      deliveryTariffName: order.deliveryTariff?.name ?? null,
      paymentMethodName: order.servicePaymentMethod?.name
        ? intl.formatMessage({ id: `app.payment_method.${order.servicePaymentMethod.name.toLowerCase()}` as MessageId })
        : null,
    }),
    [intl],
  );
};

export default useOrderToExcelRow;
