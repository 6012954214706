import { FC, useMemo, useState } from 'react';
import { getCalendarDates } from 'src/components/datetime/WeekPicker/helpers';
import { chunks } from 'src/utils/chunks';
import MonthTabDayCell from './MonthTabDayCell';
import clsx from 'clsx';
import { isSameDay, isSameMonth } from 'date-fns';
import useTechnicianMonthOrders from 'src/api/hooks/queries/useTechnicianMonthOrders';
import useCalendarSingleTechnicianIdParam from 'src/pages/CalendarPage/hooks/useCalendarSingleTechnicianIdParam';
import parseISO from 'date-fns/esm/fp/parseISO/index.js';
import { OrderListItemResponse, OrderResponse } from 'src/api';
import useCalendarSelectedDateParam from 'src/pages/CalendarPage/hooks/useCalendarSelectedDateParam';
import useOrder from 'src/api/hooks/queries/useOrder';
import WithLoader from 'src/components/utils/WithLoader';
import EventInfoPopup from 'src/components/EventsGrid/components/EventInfoPopup';
import useLastDefined from 'src/hooks/useLastDefined';

export type MonthTabGridProps = {
  className?: string;
};

const MonthTabGrid: FC<MonthTabGridProps> = () => {
  const [selectedDate] = useCalendarSelectedDateParam();
  const dates = useMemo(() => getCalendarDates(selectedDate), [selectedDate]);
  const weekRows = useMemo(() => chunks(dates, 7), [dates]);
  const [technicianId] = useCalendarSingleTechnicianIdParam();
  const { data: orders } = useTechnicianMonthOrders(
    technicianId ?? 0,
    selectedDate.getMonth(),
    selectedDate.getFullYear(),
    { enabled: !!technicianId },
  );

  const ordersByDate = useMemo(
    () =>
      dates.reduce((acc, date) => {
        const dateOrders = orders?.filter((i) => i.datetime && isSameDay(parseISO(i.datetime), date)) ?? [];
        acc[date.getTime()] = dateOrders;
        return acc;
      }, {} as Record<number, OrderListItemResponse[]>),
    [dates, orders],
  );

  const [showEventInfo, setShowEventInfo] = useState(false);
  const [shownOrderId, setShownOrderId] = useState<number | null>(null);
  const { data: order, isFetching: isFetchingOrder } = useOrder(shownOrderId ?? 0, { enabled: !!shownOrderId });
  const orderData = useLastDefined(order);

  const handleOrderClick = (orderId: number): void => {
    setShownOrderId(orderId);
    setShowEventInfo(true);
  };
  return (
    <WithLoader isLoading={isFetchingOrder}>
      <div className='w-full flex flex-col'>
        {weekRows.map((week, index) => (
          <div
            key={index}
            className={clsx('flex w-full', index < weekRows.length - 1 && 'border-b border-neutral-200')}
          >
            {week.map((date, index) => (
              <MonthTabDayCell
                key={date.getTime()}
                date={date}
                onOrderClick={handleOrderClick}
                orders={ordersByDate[date.getTime()]}
                greyedOut={!isSameMonth(selectedDate, date)}
                className={clsx('flex-1 py-2 px-1.5', index < week.length - 1 && 'border-r border-r-neutral-200')}
              />
            ))}
          </div>
        ))}
      </div>
      <EventInfoPopup
        open={showEventInfo && !isFetchingOrder && !!orderData}
        onClose={() => setShowEventInfo(false)}
        order={orderData ?? ({} as OrderResponse)}
      />
    </WithLoader>
  );
};

export default MonthTabGrid;
