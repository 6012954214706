import clsx from 'clsx';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { OrderTypeRepsonse, PaymentMethodResponse, SlConfigGenSchemaDto } from 'src/api';
import useUpdateOrderTypeSlConfig from 'src/api/hooks/mutations/orderTypes/useUpdateOrderTypeSlConfig';
import Button from 'src/components/Button';
import { CheckboxControlled } from 'src/components/fields/Checkbox';
import { CheckboxGroupRhfc } from 'src/components/fields/CheckboxGroup';
import { SelectControlled } from 'src/components/fields/Select';
import { useFormBuilderState } from 'src/components/FormBuilder/context';
import { formBuilderDtoToState, formBuilderStateToDto } from 'src/components/FormBuilder/helpers';
import { MessageId } from 'src/types/commonTypes';
import FormBuilder from '../../../../components/FormBuilder';
import { GENERAL_INFO_FIELDS, PRODUCT_HIDDEN_FIELDS } from './constants';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export type OrderTypeConfigurationFormProps = {
  className?: string;
  orderType: OrderTypeRepsonse;
  paymentMethods: PaymentMethodResponse[];
};

// TOOD: refactor ForbBuilder to use form state, current implementation is overcomplicated and inefficient for no reason
const OrderTypeConfigurationForm: FC<OrderTypeConfigurationFormProps> = ({ className, orderType, paymentMethods }) => {
  const intl = useIntl();
  const form = useForm<SlConfigGenSchemaDto>({
    defaultValues: {
      ...orderType.slConfig,
    },
  });
  const { control, handleSubmit } = form;

  const updateOrderTypeSlConfig = useUpdateOrderTypeSlConfig();
  const onSubmit = (data: SlConfigGenSchemaDto): void => {
    updateOrderTypeSlConfig.mutate({
      ...orderType.slConfig,
      ...data,
      id: orderType.id,
    });
  };

  const sectionsFormBuilderState = useFormBuilderState(() => {
    const defaultState = formBuilderDtoToState({ sections: orderType?.slConfig?.sections ?? [] });

    return {
      defaultSections: defaultState.sections,
      defaultFields: defaultState.fields,
    };
  });

  const productSectionsFormBuilderState = useFormBuilderState(() => {
    const defaultState = formBuilderDtoToState({ sections: orderType?.slConfig?.productsSections ?? [] });

    return {
      defaultSections: defaultState.sections,
      defaultFields: defaultState.fields,
    };
  });

  const handleSaveAllSections = (): void => {
    updateOrderTypeSlConfig.mutate({
      ...orderType.slConfig,
      sections: formBuilderStateToDto(sectionsFormBuilderState).sections,
      productsSections: formBuilderStateToDto(productSectionsFormBuilderState).sections,
      id: orderType.id,
    });
  };

  return (
    <FormProvider {...form}>
      <div className={clsx(className, 'flex flex-col')}>
        <form
          onSubmit={handleSubmit(onSubmit, console.error)}
          className='grid grid-cols-1 lg:grid-cols-2 gap-x-20 gap-y-8 border-b broder-gray-300 w-auto mb-10'
        >
          <div className='flex flex-col gap-y-4'>
            <span className='text-2xl mb-4'>
              <FormattedMessage id='app.finish_order.general_info' />
            </span>
            <details className='flex w-full gap-3 mb-4'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='showBranchInHeader'
                  label={<FormattedMessage id='app.order.order_type.configuration.show_branch_in_header' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.show_branch_in_header.hint' />
              </span>
            </details>
            <SelectControlled
              label={<FormattedMessage id='app.order.order_type.configuration.default_payment_method' />}
              className='max-w-xs'
              control={control}
              name='defaultPaymentMethodId'
              options={paymentMethods}
              getOptionLabel={(option) =>
                intl.formatMessage({
                  id: `app.payment_method.${option.name.toLocaleLowerCase()}` as MessageId,
                })
              }
              getOptionValue={(option) => option.id}
            />

            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='allowManualPrices'
                  label={<FormattedMessage id='app.order.order_type.configuration.manual_prices' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.manual_prices.hint' />
              </span>
            </details>

            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='hideRealTimeInPDF'
                  label={<FormattedMessage id='app.order.order_type.configuration.hide_real_time_in_pdf' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.hide_real_time_in_pdf.hint' />
              </span>
            </details>

            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='allowManualRealTime'
                  label={<FormattedMessage id='app.order.order_type.configuration.manual_real_time' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.manual_real_time.hint' />
              </span>
            </details>

            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='allowManualInvoicePrices'
                  label={<FormattedMessage id='app.order.order_type.configuration.manual_invoice_prices' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.manual_invoice_prices.hint' />
              </span>
            </details>

            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='hidePricesForWork'
                  label={<FormattedMessage id='app.order.order_type.configuration.hide_work_prices' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.hide_work_prices.hint' />
              </span>
            </details>

            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='hidePricesForMaterilas'
                  label={<FormattedMessage id='app.order.order_type.configuration.hide_material_prices' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.hide_material_prices.hint' />
              </span>
            </details>

            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='hidePricesForDelivery'
                  label={<FormattedMessage id='app.order.order_type.configuration.hide_delivery_prices' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.hide_delivery_prices.hint' />
              </span>
            </details>

            <CheckboxGroupRhfc
              label={<FormattedMessage id='app.order.order_type.configuration.hide_prices_for' />}
              control={control}
              name='hidePricesFor'
              options={paymentMethods.map((option) => ({
                label: intl.formatMessage({
                  id: `app.payment_method.${option.name.toLocaleLowerCase()}` as MessageId,
                }),
                value: option.id,
                name: option.name,
              }))}
            />
            <CheckboxGroupRhfc
              control={control}
              name='generalHiddenFeilds'
              options={GENERAL_INFO_FIELDS}
              label={<FormattedMessage id='app.common.hide' />}
            />

            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='hideDeliverySection'
                  label={<FormattedMessage id='app.order.order_type.configuration.hide_delivery_section' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.hide_delivery_section.hint' />
              </span>
            </details>

            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='hidePaymentSection'
                  label={<FormattedMessage id='app.order.order_type.configuration.hide_payment_section' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.hide_payment_section.hint' />
              </span>
            </details>

            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='hideWorkSection'
                  label={<FormattedMessage id='app.order.order_type.configuration.hide_work_section' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.hide_work_section.hint' />
              </span>
            </details>
          </div>
          <div className='flex flex-col gap-4'>
            <span className='text-2xl mb-4'>
              <FormattedMessage id='app.order.section_title.products' />
            </span>
            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='hideMaterials'
                  label={<FormattedMessage id='app.order.order_type.configuration.hide_materials' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.hide_materials.hint' />
              </span>
            </details>
            <CheckboxGroupRhfc
              label={<FormattedMessage id='app.common.hide' />}
              control={control}
              name='productsHiddenFeilds'
              options={PRODUCT_HIDDEN_FIELDS}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <span className='text-2xl mb-4'>
              <FormattedMessage id='app.order.section_title.products' />
            </span>
            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <CheckboxControlled
                  control={control}
                  name='hideMaterials'
                  label={<FormattedMessage id='app.order.order_type.configuration.hide_materials' />}
                />
              </summary>
              <span className='mt-2 mb-4 pb-3 border-b-gray-300 border-b'>
                <FormattedMessage id='app.order.order_type.configuration.hide_materials.hint' />
              </span>
            </details>
            <CheckboxGroupRhfc
              label={<FormattedMessage id='app.common.hide' />}
              control={control}
              name='productsHiddenFeildsInPDF'
              options={PRODUCT_HIDDEN_FIELDS}
            />
          </div>
          <Button type='submit' className='max-w-xs mb-10'>
            <FormattedMessage id='app.buttons.save' />
          </Button>
        </form>

        <div className='flex flex-col gap-y-6'>
          <div className='grid grid-cols-1 gap-4 xl:grid-cols-2 w-full'>
            <FormBuilder state={sectionsFormBuilderState} />
            <FormBuilder
              state={productSectionsFormBuilderState}
              title={<FormattedMessage id='app.order_type.sl_config.product_sections' />}
            />
          </div>
          <Button type='submit' className='max-w-xs' onClick={handleSaveAllSections}>
            <FormattedMessage id='app.buttons.save' />
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

OrderTypeConfigurationForm.displayName = 'OrderTypeConfigurationForm';

export default OrderTypeConfigurationForm;
