import { FC } from 'react';
import { OrderListItemResponse } from 'src/api';
import { twMerge } from 'tailwind-merge';
import MonthTabSmallEvent from './MonthTabSmallEvent';
import clsx from 'clsx';

export type MonthTabDayCellProps = {
  className?: string;
  greyedOut?: boolean;
  date: Date;
  orders: OrderListItemResponse[];
  onOrderClick?: (orderId: number) => void;
};

const MonthTabDayCell: FC<MonthTabDayCellProps> = ({ className, greyedOut, date, orders, onOrderClick }) => {
  return (
    <div className={twMerge('flex flex-col justify-between h-40 bg-white', greyedOut && 'bg-neutral-50', className)}>
      <span className={clsx('text-right', greyedOut && 'text-gray-400')}>{date.getDate()}</span>
      <div className='flex flex-col gap-2'>
        {orders.map((order) => (
          <MonthTabSmallEvent key={order.id} data={order} onOrderClick={onOrderClick} />
        ))}
      </div>
    </div>
  );
};

export default MonthTabDayCell;
