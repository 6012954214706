import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { OrderListItemResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

type QueryKey = ReturnType<typeof queryKeysFactory.technicianMonthOrders.list>['queryKey'];
type QueryOptions = Omit<
  UseQueryOptions<OrderListItemResponse[], unknown, OrderListItemResponse[], QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useTechnicianMonthOrders = (
  technicianId: number,
  monthIndex: number,
  year: number,
  options?: QueryOptions,
): UseQueryResult<OrderListItemResponse[]> => {
  const apiClient = useApiClient();

  const { queryKey } = queryKeysFactory.technicianMonthOrders.list(technicianId, year, monthIndex);

  return useQuery(queryKey, () => apiClient.calendar.getTechnicianMonthOrders({ technicianId, monthIndex, year }), {
    enabled: !!technicianId,
    ...options,
  });
};

export default useTechnicianMonthOrders;
